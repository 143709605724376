<template>
  <div
    class="csn-sss"
    :class="{ 'csn-sss-displayed': SHOWS_SUPPORT_SIDE_MENU }"
  >
    <div class="csn-sss-header">
      <button @click="handleCloseClick" class="csn-sss-arrow-back">
        <SlideArrow />
      </button>

      <button @click="handleCloseClick" class="csn-notification-menu-close-btn">
        <CloseIcon />
      </button>
    </div>

    <SupportSidebar @close="handleCloseClick" />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

import {
  SUPPORT_SIDE_MENU,
  Module,
  SHOWS_SUPPORT_SIDE_MENU,
  TOGGLE_SUPPORT_SIDE_MENU,
} from '@/constants'

export default {
  name: SUPPORT_SIDE_MENU,
  components: {
    SupportSidebar: () => import('@/views/Support/SupportSidebar'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  computed: {
    ...mapState(Module.MAIN, [SHOWS_SUPPORT_SIDE_MENU]),
  },
  methods: {
    ...mapMutations(Module.MAIN, [TOGGLE_SUPPORT_SIDE_MENU]),
    async handleCloseClick() {
      this.TOGGLE_SUPPORT_SIDE_MENU(false)
    },
  },
}
</script>
